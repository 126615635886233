import { useSnackbar } from 'notistack'
import { TypeNotificationVariant } from '../../types/general'
import { Notification } from '../../../components/UI/Notification/Notification'

export const useEnqueueSnackbar = () => {
   const { enqueueSnackbar } = useSnackbar()

   return (
      variant: TypeNotificationVariant,
      description: string,
      message: string
   ) => {
      enqueueSnackbar(message, {
         anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
         },
         content: (id) => {
            return (
               <Notification
                  variant={variant}
                  message={message}
                  description={description}
                  id={id}
               />
            )
         },
      })
   }
}
