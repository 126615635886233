import { Navigate, Route, Routes } from 'react-router-dom'
import { ROUTES } from '../utils/constants/routes'
import { PrivateRouteRenderByRole } from './Private/PrivateRouteRenderByRole'
import { lazy, Suspense } from 'react'
import { SpinnerContainer } from '../components/UI/Spinner/SpinnerContainer'
import Spinner from '../components/UI/Spinner/Spinner'

const LazyOfficePage = lazy(() => import('../containers/Office.Page'))
const LazyAccountingPage = lazy(
   () => import('../containers/Accounting/Accounting.Page')
)

const LazyCargoDetailPage = lazy(
   () =>
      import(
         '../containers/Accounting/PackingListByDetail/PackingListDetail.Page'
      )
)

const LazyCategoriesPage = lazy(() => import('../containers/Categories.Page'))
const LazyOfficeUserPage = lazy(() => import('../containers/OfficeUsers.Page'))
const LazyPaymentMethodPage = lazy(
   () => import('../containers/PaymentMethod.Page')
)

// TODO need the suspense component
export const DashboardRoutes = () => {
   return (
      <Suspense
         fallback={
            <SpinnerContainer>
               <Spinner size='6rem' />
            </SpinnerContainer>
         }
      >
         <Routes>
            <Route
               path='/'
               element={<Navigate to={ROUTES.ACCOUNTING.INDEX} />}
            />
            <Route
               path={`${ROUTES.ACCOUNTING.INDEX}/*`}
               element={
                  <PrivateRouteRenderByRole
                     RouteComponent={<LazyAccountingPage />}
                     roles={['ROLE_ADMIN']}
                  />
               }
            />
            <Route
               path={`${ROUTES.ACCOUNTING_CARGO_DETAIL.INDEX}`}
               element={
                  <PrivateRouteRenderByRole
                     RouteComponent={<LazyCargoDetailPage />}
                     roles={['ROLE_ADMIN']}
                  />
               }
            />
            <Route
               path={`${ROUTES.OFFICE_USERS.INDEX}/*`}
               element={
                  <PrivateRouteRenderByRole
                     RouteComponent={<LazyOfficeUserPage />}
                     roles={['ROLE_ADMIN']}
                  />
               }
            />{' '}
            <Route
               path={`${ROUTES.CATEGORIES.INDEX}/*`}
               element={
                  <PrivateRouteRenderByRole
                     RouteComponent={<LazyCategoriesPage />}
                     roles={['ROLE_ADMIN']}
                  />
               }
            />
            <Route
               path={`${ROUTES.OFFICE.INDEX}/*`}
               element={
                  <PrivateRouteRenderByRole
                     RouteComponent={<LazyOfficePage />}
                     roles={['ROLE_ADMIN']}
                  />
               }
            />
            <Route
               path={`${ROUTES.PAYMENT_METHOD.INDEX}/*`}
               element={
                  <PrivateRouteRenderByRole
                     RouteComponent={<LazyPaymentMethodPage />}
                     roles={['ROLE_ADMIN']}
                  />
               }
            />
         </Routes>
      </Suspense>
   )
}
