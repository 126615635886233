import React, { useEffect, useState } from 'react'
import { AppRoutes } from './routes/AppRoutes'
import { Navigate, useLocation } from 'react-router-dom'
import { ROUTES } from './utils/constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { authActions, getIsAuthorized } from './redux/slices/auth-slice'
import { getStoragedItem } from './utils/helpers/local-storage-helpers'
import { LOGIN_INFO_KEY } from './utils/constants/general'
import { SpinnerContainer } from './components/UI/Spinner/SpinnerContainer'
import Spinner from './components/UI/Spinner/Spinner'

function App() {
   const dispatch = useDispatch()
   const location = useLocation()
   const isAuthorized = useSelector(getIsAuthorized)
   const [isLoading, setIsLoading] = useState(true)

   useEffect(() => {
      const LOGIN_INFO: { token: string } = getStoragedItem(LOGIN_INFO_KEY)
      if (LOGIN_INFO?.token) {
         dispatch(authActions.setCredentials(LOGIN_INFO.token))
      }
      setIsLoading(false)
   }, [])

   if (isLoading)
      return (
         <SpinnerContainer>
            <Spinner size='6rem' />
         </SpinnerContainer>
      )

   if (location.pathname === '/')
      return <Navigate to={ROUTES.DASHBOARD} replace />

   return <AppRoutes isAuthorized={isAuthorized} />
}

export default App
