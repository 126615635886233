import {
   CircularProgress,
   IconButton as MuiIconButton,
   IconButtonProps,
   styled,
} from '@mui/material'
import { jsx } from '@emotion/react'
import JSX = jsx.JSX
import React from 'react'

interface IIconButton {
   isLoading?: boolean
   startIcon?: React.ReactNode
}

type Props = IIconButton & IconButtonProps

const StyledIconButton = styled(MuiIconButton)({
   padding: 0,
   textTransform: 'initial',
   fontWeight: 500,
   fontSize: '14px',
})

export const IconButton = ({
   startIcon,
   isLoading,
   ...other
}: Props): JSX.Element => {
   return (
      <StyledIconButton {...other}>
         {isLoading ? <CircularProgress /> : startIcon}
      </StyledIconButton>
   )
}
