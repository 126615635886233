import {
   DEV_SERVER_URL,
   LOGIN_INFO_KEY,
} from '../../../utils/constants/general'
import {
   BaseQueryApi,
   createApi,
   FetchArgs,
   fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import { getStoragedItem } from '../../../utils/helpers/local-storage-helpers'

const baseQuery = fetchBaseQuery({
   baseUrl: DEV_SERVER_URL,
   prepareHeaders: (headers) => {
      const loginInfo = getStoragedItem(LOGIN_INFO_KEY)

      if (loginInfo?.token) {
         headers.set('Authorization', `Bearer ${loginInfo.token}`)
      }

      return headers
   },
})

// TODO need to replace
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const baseQueryWithReauth: BaseQueryFn<
   string | FetchArgs,
   BaseQueryApi
> = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: any) => {
   const result = await baseQuery(args, api, extraOptions)

   if (result?.error?.status === 401) {
      // eslint-disable-next-line no-console
      console.log(
         `Here must be refresh token request, but we haven't endpoint for it`
      )
      // api.dispatch(logout())

      return { data: null, error: null, meta: {} }
   }
   return result
}

export const commonApi = createApi({
   reducerPath: 'api',
   endpoints: () => ({}),
   baseQuery: baseQueryWithReauth,
})
