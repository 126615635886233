import { configureStore } from '@reduxjs/toolkit'
import { authApi } from './api/auth/auth-api'
import { authSlice } from './slices/auth-slice'
import { commonApi } from './api/common-config/common-api'

export const store = configureStore({
   reducer: {
      [commonApi.reducerPath]: commonApi.reducer,
      [authApi.reducerPath]: authApi.reducer,
      [authSlice.name]: authSlice.reducer,
   },
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([commonApi.middleware, authApi.middleware]),
})
