import { Box, CircularProgress, CircularProgressProps } from '@mui/material'

interface ISpinner {
   size: string
}

type Props = ISpinner & CircularProgressProps

export default function Spinner({
   size,
   color,
   ...otherProps
}: Props): JSX.Element {
   return (
      <Box sx={{ display: 'flex' }} {...otherProps}>
         <CircularProgress color={color} size={size} />
      </Box>
   )
}
