import { Box, styled } from '@mui/material'
import { LoginForm } from '../components/Login/LoginForm'

import loginBackground from '../assets/images/login_image.png'
import { useLoginRequestMutation } from '../redux/api/auth/auth-api'
import { useEnqueueSnackbar } from '../utils/helpers/hooks/useSnackbar'
import { ICredential } from '../utils/types/general'
import { loadErrorMessage } from '../utils/helpers/general'

const Main = styled('main')({
   display: 'flex',
   flex: 1,
   width: '100%',
})

const Background = styled('img')({
   width: '50%',
   height: '100vh',
   objectFit: 'cover',
})

const Wrapper = styled(Box)({
   display: 'flex',
   justifyContent: 'center',
   flex: 1,
   form: {
      maxWidth: 487,
      width: '50%',
   },
})

export const LoginPage = () => {
   const [asyncLoginRequest, { isLoading }] = useLoginRequestMutation()
   const notify = useEnqueueSnackbar()

   const loginHandler = (credentials: ICredential) => {
      asyncLoginRequest(credentials)
         .unwrap()
         .catch((e) => {
            notify('error', loadErrorMessage(e), e?.error || 'Error')
         })
   }

   return (
      <Main>
         <Background src={loginBackground} />
         <Wrapper>
            <LoginForm isLoading={isLoading} onGetCredentials={loginHandler} />
         </Wrapper>
      </Main>
   )
}
