export const DEV_SERVER_URL = 'https://api.shipsrs.com/api/'

export const NAV_LINKS = [
   {
      title: 'Accounting',
      path: 'accounting',
   },
   {
      title: 'Office users',
      path: 'office-users',
   },
   {
      title: 'Categories',
      path: 'categories',
   },
   {
      title: 'Office',
      path: 'office',
   },
   {
      title: 'Payment method',
      path: 'payment-method',
   },
]

export const LOGIN_INFO_KEY = '@SRS_ADMIN_JWT_TOKEN_KEY'

export const paymentCardList = [
   {
      title: 'Total payment',
      key: 'totalPayments',
   },
   {
      title: 'Paid',
      key: 'paid',
   },
   {
      title: 'Unpaid',
      key: 'unpaid',
   },
]

export const ListTableHead = [
   'Packing list',
   'Office',
   'Date',
   'Total packages',
   'Payment',
   'Unpaid',
]

export const ListTableHeadPackingListDetail = [
   'ID',
   'Payment status',
   'Payment',
   'Payment method',
   'From',
   'To',
   'Status',
   'Actions',
]

export const renderStatusPackingListById = {
   ['CREATED']: {
      color: '#FCB408',
      text: 'Created',
   },
   ['READY_TO_SHIP']: {
      color: '#EA8C00',
      text: 'Ready to ship',
   },
   ['SHIPPED']: {
      color: '#298EEA',
      text: 'Shipped',
   },
   ['OFFICE_B_RECEIVED']: {
      color: '#E420E8',
      text: 'Office B received',
   },
   ['DELIVERED']: {
      color: '#25D93B',
      text: 'Delivered',
   },
}

export const isPhone: boolean = window.screen.width < 490
