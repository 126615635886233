import React from 'react'
import { ButtonProps, styled } from '@mui/material'
import { Button as MuiButton } from '@mui/material'
import { jsx } from '@emotion/react'
import JSX = jsx.JSX
import Spinner from '../Spinner/Spinner'

interface IButtonProps {
   size?: 'small' | 'medium' | 'large'
   disabled?: boolean
   variant?: 'contained' | 'outlined' | 'text'
   children?: JSX.Element | JSX.Element[] | string | string[]
   icon?: React.ReactNode
   fullWidth?: boolean
   loading?: boolean
}

type Props = IButtonProps & ButtonProps

const StyledButton = styled(MuiButton)({
   minWidth: 117,
   minHeight: 40,
   borderRadius: 48,
   '&.MuiButton-containedSizeLarge': {
      fontSize: 18,
   },
   '&.MuiButton-containedSizeMedium': {
      fontSize: 16,
   },
   boxShadow: 'none',
   '&:hover': {
      boxShadow: 'none',
   },
})

export const Button: React.FC<Props> = ({
   size,
   disabled,
   variant,
   children,
   icon,
   fullWidth,
   loading,
   ...otherProps
}) => {
   return (
      <StyledButton
         size={size}
         fullWidth={fullWidth}
         variant={variant}
         startIcon={icon}
         disabled={disabled || loading}
         {...otherProps}
      >
         {loading ? <Spinner size='2rem' /> : children}
      </StyledButton>
   )
}
