import { InputAdornment, TextFieldProps } from '@mui/material'
import { Input } from './Input'
import React, { ChangeEvent } from 'react'
import { VisibilityIcon, VisibilityOffIcon } from '../../../assets/icons/UI'
import { IconButton } from '../Button/IconButton'

interface IPasswordInput {
   value: string
   label: string
   onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

type Props = IPasswordInput & TextFieldProps

export const PasswordInput = ({ value, label, onChange, ...other }: Props) => {
   const [showPassword, setShowPassword] = React.useState<boolean>(false)
   const handleClickShowPassword = () => setShowPassword((show) => !show)

   return (
      <Input
         disableClear
         value={value}
         label={label}
         onChange={onChange}
         type={showPassword ? 'text' : 'password'}
         InputProps={{
            endAdornment: (
               <InputAdornment position='end'>
                  <IconButton
                     onClick={handleClickShowPassword}
                     startIcon={
                        showPassword ? (
                           <VisibilityOffIcon />
                        ) : (
                           <VisibilityIcon />
                        )
                     }
                  />
               </InputAdornment>
            ),
         }}
         {...other}
      />
   )
}
