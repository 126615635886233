import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
   DEV_SERVER_URL,
   LOGIN_INFO_KEY,
} from '../../../utils/constants/general'
import { authActions } from '../../slices/auth-slice'
import { addItemToStorage } from '../../../utils/helpers/local-storage-helpers'

export const authApi = createApi({
   reducerPath: 'authApi',
   baseQuery: fetchBaseQuery({
      baseUrl: `${DEV_SERVER_URL}`,
   }),
   endpoints: (builder) => ({
      loginRequest: builder.mutation({
         query: (credentials) => ({
            url: 'auth/admin',
            method: 'POST',
            body: credentials,
         }),
         onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
            try {
               const { data } = await queryFulfilled
               dispatch(authActions.setCredentials(data))
               addItemToStorage(data, LOGIN_INFO_KEY)
               return data
            } catch (e) {
               return e
            }
         },
      }),
   }),
})

export const { useLoginRequestMutation } = authApi
