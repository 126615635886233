import { styled } from '@mui/material'
import { Header } from './Header/Header'
import { DashboardRoutes } from '../routes/DashboardRoutes'

export const GridContainer = styled('div')`
   height: 100%;
   display: grid;
   grid-template-columns: 250px 1.5fr;
   grid-template-rows: 60px 1.8fr;
   padding: 0 100px 0 100px;
   grid-template-areas:
      'header header'
      'content content';
   @media (max-width: 480px) {
      grid-template-columns: 1fr;
      padding: 0 20px;
   }
   & header {
      grid-area: header;
   }
   & main {
      grid-area: content;
      overflow-y: auto;
      height: 100%;
      scrollbar-width: none;
      &::-webkit-scrollbar {
         display: none;
      }
   }
`

export const DashboardLayout = () => {
   return (
      <GridContainer>
         <Header />
         <main>
            <DashboardRoutes />
         </main>
      </GridContainer>
   )
}
