import { styled } from '@mui/material'
import React from 'react'

interface Props {
   children: React.ReactNode
}

const Container = styled('div')(() => ({
   display: 'grid',
   placeItems: 'center',
   flex: '1 0',
   height: '100%',
   width: '100%',
}))

export const SpinnerContainer = ({
   children,
   ...otherProps
}: Props): JSX.Element => {
   return <Container {...otherProps}>{children}</Container>
}
