import { Route, Routes } from 'react-router-dom'
import { ROUTES } from '../utils/constants/routes'
import React from 'react'
import { PrivateAuthRoute } from './Private/PrivateAuthRoute'
import { DashboardLayout } from '../layout/DashboardLayout'
import { LoginPage } from '../containers/Login.Page'
import { PrivacyPolicyPage } from '../containers/PrivacyPolicy.Page'

type Props = {
   isAuthorized: boolean
}

export const AppRoutes: React.FC<Props> = ({ isAuthorized }) => {
   return (
      <Routes>
         <Route
            index
            path={ROUTES.LOGIN}
            element={
               <PrivateAuthRoute
                  RouteComponent={<LoginPage />}
                  fallbackPath={ROUTES.DASHBOARD}
                  isAllowed={!isAuthorized}
               />
            }
         />
         <Route
            path={`${ROUTES.DASHBOARD}/*`}
            element={
               <PrivateAuthRoute
                  RouteComponent={<DashboardLayout />}
                  fallbackPath={ROUTES.LOGIN}
                  isAllowed={isAuthorized}
               />
            }
         />
         <Route
            path={`${ROUTES.PRIVACY_POLICY.INDEX}`}
            element={<PrivacyPolicyPage />}
         />
      </Routes>
   )
}
