import { List, styled } from '@mui/material'
import { NAV_LINKS } from '../../utils/constants/general'
import { NavLink } from './NavLink'

const StyledList = styled(List)({
   display: 'flex',
})

export const NavLinks = () => {
   return (
      <StyledList>
         {NAV_LINKS.map((link) => {
            return (
               <NavLink key={link.path} title={link.title} path={link.path} />
            )
         })}
      </StyledList>
   )
}
