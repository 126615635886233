import { Navigate } from 'react-router-dom'
import { ROUTES } from '../../utils/constants/routes'
import React from 'react'
import { jsx } from '@emotion/react'
import JSX = jsx.JSX

type Props = {
   RouteComponent: JSX.Element
   fallbackPath: string
   isAllowed: boolean
}

export const PrivateAuthRoute = ({
   RouteComponent,
   fallbackPath = ROUTES.LOGIN,
   isAllowed,
}: Props): JSX.Element => {
   if (!isAllowed) {
      return <Navigate to={fallbackPath} replace />
   }

   return RouteComponent
}
