import {
   ErrorNotificationIcon,
   RemoveNotificationIcon,
   SuccessNotificationIcon,
   WarningNotificationIcon,
} from '../../../assets/icons/UI'
import { Box, styled, Typography } from '@mui/material'
import { IconButton } from '../Button/IconButton'
import React, { forwardRef, useCallback } from 'react'
import {
   INotificationVariant,
   TypeNotificationVariant,
} from '../../../utils/types/general'
import { useSnackbar } from 'notistack'

const COLORS = {
   error: '#F12D2D',
   success: '#34C759',
   warning: '#FCB408',
}

const STATUS_ICONS = {
   error: <ErrorNotificationIcon />,
   success: <SuccessNotificationIcon />,
   warning: <WarningNotificationIcon />,
}

interface ITypography {
   variant: TypeNotificationVariant
   children: React.ReactNode | React.ReactNode[]
}

interface INotification {
   variant: TypeNotificationVariant
   message: string | React.ReactNode
   description?: string
   id?: string | number
}

type Props = INotification

const StyledAlert = styled(Box)<INotificationVariant>(({ variant }) => ({
   width: 380,
   height: 76,
   backgroundColor: 'white',
   borderRadius: 4,
   borderLeft: `solid ${COLORS[variant]} 5px`,
   fontSize: 12,
   fontWeight: '500',
   display: 'flex',
   justifyContent: 'space-between',
   overflow: 'hidden',
   padding: 0,
   alignItems: 'center',
}))

const StyledContainer = styled(Box)(() => ({
   paddingLeft: 13,
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center',
}))

const TextsBox = styled(Box)({
   maxWidth: '90%',
   marginLeft: 16,
   overflow: 'hidden',
   display: '-webkit-box',
   WebkitLineClamp: 3,
   WebkitBoxOrient: 'vertical',
})

const Title = styled(({ variant, ...otherProps }: ITypography) => (
   <Typography {...otherProps} variant={variant as never} />
))<ITypography>(({ variant }) => ({
   color: COLORS[variant],
   fontWeight: 500,
   fontSize: 16,
}))

const Text = styled(Typography)({
   fontFamily: 'Inter',
   color: '#A7A7A7',
   fontWeight: 500,
   fontSize: 14,
})

export const Notification: React.FC<Props> = forwardRef<HTMLDivElement, Props>(
   ({ variant = 'error', message, description, id }: Props, ref) => {
      const { closeSnackbar } = useSnackbar()

      const closeSnackBarHandler = useCallback(() => {
         closeSnackbar(id)
      }, [id, closeSnackbar])

      return (
         <StyledAlert variant={variant} ref={ref}>
            <StyledContainer>
               {STATUS_ICONS[variant]}
               <TextsBox>
                  <Title variant={variant}>{message}</Title>
                  {description ? <Text> {description}</Text> : null}
               </TextsBox>
            </StyledContainer>
            <IconButton
               style={{ marginRight: '16px' }}
               startIcon={<RemoveNotificationIcon />}
               onClick={closeSnackBarHandler}
            />
         </StyledAlert>
      )
   }
)
