import { PasswordInput } from '../UI/Input/PasswordInput'
import React, { useState } from 'react'
import { styled, Typography } from '@mui/material'
import { Input } from '../UI/Input/Input'
import { LoginLogoIcon } from '../../assets/icons'
import { Button } from '../UI/Button/Button'
import { ICredential } from '../../utils/types/general'

interface ILoginForm {
   onGetCredentials: (credentials: ICredential) => void
   isLoading: boolean
}

const Form = styled('form')({
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'center',
   alignItems: 'center',
   '&:nth-of-type(3)': {
      color: 'red',
   },

   '.password-input': {
      marginTop: 25,
   },

   '.save-button': {
      marginTop: 23,
   },
})

const Title = styled(Typography)({
   marginBottom: 30,
   marginTop: 30,
   fontWeight: 400,
   fontSize: 20,
   color: '#212121',
})

export const LoginForm: React.FC<ILoginForm> = ({
   onGetCredentials,
   isLoading,
}) => {
   const [password, setPassword] = useState<string>('')
   const [email, setEmail] = useState<string>('')
   const [isFieldsValid, setIsFieldsValid] = useState<boolean>(true)

   const clearLoginHandler = () => {
      setEmail('')
   }

   const submitHandler = (e: { preventDefault: () => void }) => {
      e.preventDefault()
      if (email.length === 0 || password.length === 0) {
         return setIsFieldsValid(false)
      }
      onGetCredentials({ email, password })
   }

   return (
      <Form onSubmit={submitHandler}>
         <LoginLogoIcon />
         <Title>Welcome to Silk Road Shipping!</Title>
         <Input
            error={!isFieldsValid}
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label='Login'
            disableClear={false}
            onClear={clearLoginHandler}
         />
         <PasswordInput
            error={!isFieldsValid}
            fullWidth
            className='password-input'
            value={password}
            label='Password'
            onChange={(e) => setPassword(e.target.value)}
         />
         <Button
            loading={isLoading}
            type='submit'
            className='save-button'
            variant='contained'
            fullWidth
         >
            Sign In
         </Button>
      </Form>
   )
}
