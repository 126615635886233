import React, { ChangeEvent, FC, forwardRef } from 'react'
import { Box, BoxProps, styled, TextField, TextFieldProps } from '@mui/material'
import { ClearInputIcon } from '../../../assets/icons/UI'
import { IconButton } from '../Button/IconButton'

interface IInput {
   type?: string
   value?: string
   onChange?: (e: ChangeEvent<HTMLInputElement>) => void
   label?: string
   onClear?: (inputType: any) => void
   disableClear: boolean
}

interface IWrapper {
   fullwidth?: string
}

type Props = IInput & TextFieldProps
type WrapperProps = IWrapper & BoxProps

const StyledInput = styled(TextField)({
   '.MuiInputLabel-root': {
      color: '#A7A7A7',
      paddingLeft: 8,
   },
   '.MuiInputLabel-shrink': {
      color: '#298EEA',
      paddingLeft: 8,
   },

   '.MuiInputBase-root': {
      paddingLeft: 8,
      paddingRight: 8,
   },
}) as typeof TextField

const Wrapper = styled(Box)<WrapperProps>(({ fullwidth }) => ({
   width: Boolean(fullwidth) ? '100%' : 420,
   position: 'relative',
   display: 'flex',
   alignItems: 'center',
   '& input:-internal-autofill-selected': {
      backgroundColor: 'red !important',
   },
}))

const StyledIconButton = styled(IconButton)(() => ({
   marginRight: 8,
   marginTop: 4,
   marginBottom: 4,
   position: 'absolute',
   right: 0,
}))

export const Input: FC<Props> = forwardRef(
   (
      {
         type,
         label,
         variant = 'standard',
         value,
         onChange,
         onClear,
         fullWidth,
         name,
         disableClear = true,
         ...other
      }: Props,
      ref
   ) => {
      return (
         <Wrapper fullwidth={fullWidth?.toString()}>
            <StyledInput
               type={type}
               name={name}
               label={label}
               variant={variant}
               value={value}
               onChange={onChange}
               fullWidth
               ref={ref}
               {...other}
            />
            {disableClear ? null : value && value.length !== 0 ? (
               <StyledIconButton
                  onClick={() => (onClear ? onClear(name) : () => {})}
                  startIcon={<ClearInputIcon />}
               />
            ) : null}
         </Wrapper>
      )
   }
)
