import { styled } from '@mui/material'

const Main = styled('main')({
   margin: '2.5rem 1rem 0 1rem',
   width: '80%',
})

const Title = styled('h1')({
   fontSize: '1.4rem',
   fontWeight: 'normal',
   marginBottom: '3.6rem',
})

const SubTitle = styled('h3')({ fontWeight: 'normal', marginBottom: '1.6rem' })

const Section = styled('section')({})

const SectionParagraph = styled('p')({
   marginBottom: '1.6rem',
})

const privacyList = [
   {
      title: '1. Information We Collect',
      content: [
         '1.1. Personal Information: We may collect personal information, such as your name, contact details, address, and payment information, when you interact with us for the purpose of availing our shipping and logistics services.',
         '1.2. Non-Personal Information: We may collect non-personal information, such as IP addresses, browser type, device information, and website usage data, to enhance and improve our services.',
      ],
   },
   {
      title: '2. Use of Information',
      content: [
         '2.1. We use the information collected to provide and improve our services, fulfill your requests, process payments, communicate with you, and ensure the smooth operation of our business.',
         '2.2. We may use your contact information to send you updates, promotions, and relevant information about our services, but only if you have provided your consent to receive such communications.',
         '2.3. We may anonymize and aggregate non-personal information for statistical and analytical purposes to understand trends, enhance our services, and improve user experience.',
      ],
   },
   {
      title: '3. Disclosure of Information',
      content: [
         '3.1. We may disclose your personal information to third-party service providers who assist us in delivering our services, such as shipping carriers, customs brokers, payment processors, and IT support providers. These third parties are obligated to handle your information securely and use it solely for the purpose of providing the requested services.',
         '3.2. We may disclose your personal information if required by law, regulation, or legal process, or if we believe it is necessary to protect our rights, property, or safety, or the rights, property, or safety of others.',
      ],
   },
   {
      title: '4. Data Security',
      content: [
         '4.1. We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.\n',
      ],
   },
   {
      title: '5. Your Rights',
      content: [
         '5.1. You have the right to access, update, and correct your personal information held by us. If you would like to exercise this right or have any privacy-related inquiries, please contact us using the following information:',
         'Email: info@silkroadshipping.biz',
         'Phone: 847 271 1988',
         'Address: 7080 N. McCormick Blvd, Lincolnwood, IL, 60712',
      ],
   },
   {
      title: "6. Children's Privacy",
      content: [
         '6.1. Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected personal information from a child, we will take steps to promptly delete it.',
      ],
   },
   {
      title: '7. Updates to this Privacy Policy',
      content: [
         '7.1. We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. The updated Privacy Policy will be posted on our website, and the "Last Updated" date will indicate the effective date of the revised policy.',
         'Please read this Privacy Policy carefully. By using our services, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy.',
         'If you have any questions or concerns about this Privacy Policy, please contact us using the provided contact information.',
      ],
   },
]

export const PrivacyPolicyPage = () => {
   return (
      <Main>
         <Title>Privacy Policy - Silk Road Shipping, LLC</Title>
         <SubTitle>Last Updated: 5/31/2023</SubTitle>
         <p>
            Silk Road Shipping, LLC ("Silk Road Shipping," "we," or "us") is
            committed to protecting your privacy and ensuring the security of
            your personal information. This Privacy Policy outlines how we
            collect, use, disclose, and protect the information we collect from
            individuals who interact with our services. By using our services,
            you consent to the practices described in this Privacy Policy.
         </p>
         <div style={{ marginBottom: '4rem' }} />
         {privacyList.map((privacy) => {
            return (
               <Section key={privacy.title}>
                  <SubTitle>{privacy.title}</SubTitle>
                  {privacy.content.map((text, index) => (
                     <>
                        <SectionParagraph key={text}>{text}</SectionParagraph>
                        {index == privacy.content.length - 1 ? (
                           <div style={{ marginBottom: '4rem' }} />
                        ) : null}
                     </>
                  ))}
               </Section>
            )
         })}
         <div style={{ height: '1rem' }} />
      </Main>
   )
}
