import { createTheme, PaletteColorOptions } from '@mui/material'

declare module '@mui/material/styles' {
   interface PaletteOptions {
      neutral: PaletteColorOptions
   }
}

export const globalTheme = createTheme({
   palette: {
      primary: {
         main: '#FCB408',
         dark: '#EBA500',
         contrastText: '#fff',
         light: '#FFFFFF',
      },
      secondary: {
         main: '#298EEA',
         dark: '#1C2333',
         light: '#828282',
      },
      success: {
         main: '#34C759',
      },
      error: {
         main: '#F12D2D',
      },
      info: {
         main: '#FFCC00',
      },
      warning: {
         main: '#FCB408',
      },
      neutral: {
         main: '#CDCDCD',
         contrastText: '#fff',
      },
   },
   typography: {
      fontFamily: 'Inter, sans-serif',
   },
   components: {
      MuiPaper: {
         styleOverrides: {
            root: {
               borderRadius: 6,
               padding: '12px 0px',
               '& .MuiList-root': {
                  padding: 0,
               },
            },
         },
      },

      MuiButton: {
         defaultProps: {
            sx: {
               textTransform: 'initial',
               fontFamily: 'Inter, sans-serif',
            },
         },
      },
   },
})
