export const getStoragedItem = (key: string): any => {
   const storagedItem = localStorage.getItem(key)
   if (storagedItem !== null) {
      return JSON.parse(storagedItem)
   }
   return null
}

export const addItemToStorage = (item: any, key: string) => {
   localStorage.setItem(key, JSON.stringify(item))
}

export const removeItemFromStorage = (key: string) => {
   localStorage.removeItem(key)
}
