export function isLinkActive(pathname: string, path: string) {
   return pathname.split('/').includes(`${path}`)
}

export const checkIsEven = (idx: number) => idx % 2 === 1

export const loadErrorMessage = (e: any) => {
   return (e && e?.data?.message) || 'Something went wrong'
}

export const validateEmail = (email: string) => {
   return String(email)
      .toLowerCase()
      .match(
         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
}
