export const ROUTES = {
   LOGIN: '/login',
   DASHBOARD: '/dashboard',
   OFFICE: {
      INDEX: 'office',
   },
   ACCOUNTING: {
      INDEX: 'accounting',
   },
   ACCOUNTING_CARGO_DETAIL: {
      INDEX: 'accounting/:cargoId',
   },
   OFFICE_USERS: {
      INDEX: 'office-users',
   },
   CATEGORIES: {
      INDEX: 'categories',
   },
   PAYMENT_METHOD: {
      INDEX: 'payment-method',
   },
   PRIVACY_POLICY: {
      INDEX: 'privacy-policy',
   },
}
