import { ListItemButton, ListItemText, styled } from '@mui/material'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { isLinkActive } from '../../utils/helpers/general'

interface INavLink {
   title: string
   path: string
   selected?: boolean
}

const ListItemTitle = styled(ListItemText)(() => ({
   '& .MuiTypography-root': {
      fontFamily: 'inherit',
   },
   margin: 0,
   padding: 0,
}))

const StyledListItemButton = styled(
   ({ title, path, selected, ...otherProps }: INavLink) => {
      return (
         <ListItemButton
            {...(otherProps as any)}
            selected={selected}
            component={Link}
            to={path}
         >
            <ListItemTitle primary={title} />
         </ListItemButton>
      )
   }
)<INavLink>(({}) => ({
   fontFamily: 'Inter',
   fontWeight: 500,
   fontSize: '16px',
   color: '#A7A7A7',
   padding: '5px 20px',
   columnGap: '15px',
   '&.Mui-selected': {
      backgroundColor: 'transparent',
      color: '#212121',
      '&:hover': {
         backgroundColor: 'transparent',
      },
   },
   '& .MuiListItemIcon-root': {
      minWidth: 'initial',
   },
}))

export const NavLink: React.FC<INavLink> = ({ title, path }) => {
   const { pathname } = useLocation()

   const isSelected = isLinkActive(pathname, path)

   return (
      <StyledListItemButton selected={isSelected} path={path} title={title} />
   )
}
