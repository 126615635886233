import { ROUTES } from '../../utils/constants/routes'
import { Navigate } from 'react-router-dom'
import { jsx } from '@emotion/react'
import JSX = jsx.JSX
import { useSelector } from 'react-redux'
import { getIsAuthorized } from '../../redux/slices/auth-slice'

type Props = {
   RouteComponent: JSX.Element
   roles: string | string[]
   fallbackPath?: string
}

export const PrivateRouteRenderByRole = ({
   RouteComponent,
   roles,
   fallbackPath = ROUTES.LOGIN,
}: Props): JSX.Element => {
   const isAuthorized = useSelector(getIsAuthorized)
   const currentUserRole = 'ROLE_ADMIN'

   if (!isAuthorized) {
      return <Navigate to={ROUTES.LOGIN} replace />
   }

   if (isAuthorized && !roles.includes(currentUserRole)) {
      return <Navigate to={fallbackPath} replace />
   }

   return RouteComponent
}
