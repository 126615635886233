import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { removeItemFromStorage } from '../../utils/helpers/local-storage-helpers'
import { LOGIN_INFO_KEY } from '../../utils/constants/general'
import { store } from '../store'

const initialState = {
   token: null,
   isAuthorized: false,
}

export const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {
      setCredentials(state, { payload }) {
         state.token = payload.token
         state.isAuthorized = true
      },
      logout: (state) => {
         state.token = null
         state.isAuthorized = false
      },
   },
})

export const authActions = authSlice.actions
export type AppDispatch = typeof store.dispatch

export const getIsAuthorized = (state: { auth: { isAuthorized: boolean } }) =>
   state.auth.isAuthorized

export const logout = createAsyncThunk(
   'auth/logout',
   async (_, { dispatch }) => {
      removeItemFromStorage(LOGIN_INFO_KEY)
      dispatch(authActions.logout())
   }
)
