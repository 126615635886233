import React, { ReactNode } from 'react'

import {
   Box,
   Modal as MuiModal,
   ModalProps,
   styled,
   Typography,
} from '@mui/material'
import { Button } from '../Button/Button'

interface IModal {
   open: boolean
   title: string
   acceptTitle: string
   cancelTitle: string
   onAccept: (e: React.MouseEvent<HTMLButtonElement>) => void
   onCancel: (e: React.MouseEvent<HTMLButtonElement>) => void
   loading?: boolean
   isDelete?: boolean
   renderActions?: boolean
   children: ReactNode
}

type Props = IModal & ModalProps

const StyledBox = styled(Box)({
   minWidth: 350,
   padding: '12px 20px',
   position: 'absolute' as const,
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   borderRadius: 10,
   backgroundColor: '#FFFFFF',
   outline: 'none',
})

const Title = styled(Typography)({})
const Actions = styled(Box)({
   display: 'flex',
   justifyContent: 'flex-end',
   alignItems: 'center',
})

const AcceptBtn = styled(Button)(({ isdelete }: { isdelete?: string }) => {
   if (Boolean(isdelete)) {
      return {
         backgroundColor: '#F12D2D',
         '&:hover': {
            backgroundColor: '#f55d5d',
         },
      }
   }
})
const CancelBtn = styled(Button)({
   color: '#A7A7A7',
})

export const Modal: React.FC<Props> = ({
   open,
   title,
   acceptTitle,
   cancelTitle,
   onAccept,
   onCancel,
   children,
   onClose,
   loading,
   isDelete,
   renderActions = true,
   ...other
}) => {
   return (
      <MuiModal {...other} open={open} onClose={onClose}>
         <StyledBox>
            <Title>{title}</Title>
            {children}
            {renderActions ? (
               <Actions>
                  <CancelBtn onClick={onCancel}>{cancelTitle}</CancelBtn>
                  <AcceptBtn
                     isdelete={isDelete?.toString()}
                     loading={loading}
                     onClick={onAccept}
                     variant='contained'
                  >
                     {acceptTitle}
                  </AcceptBtn>
               </Actions>
            ) : null}
         </StyledBox>
      </MuiModal>
   )
}
