import { styled, Typography } from '@mui/material'

const Text = styled(Typography)({
   color: '#828282',
   marginTop: 16,
   marginBottom: 16,
})

export const ModalText = ({ text }: { text: string }): JSX.Element => {
   return <Text>{text}</Text>
}
