import { styled } from '@mui/material'
import { LogoIcon, LogoutIcon } from '../../assets/icons'
import { NavLinks } from '../../components/NavLinks/NavLinks'
import { Button } from '../../components/UI/Button/Button'
import { useDispatch } from 'react-redux'
import { Modal } from '../../components/UI/Modal/Modal'
import { useState } from 'react'
import { ModalText } from '../../components/UI/Modal/ModalText'
import { AppDispatch, logout } from '../../redux/slices/auth-slice'

const StyledHeader = styled('header')({
   position: 'fixed',
   width: '90vw',
   background: '#ffffff',
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center',
   zIndex: 100,
   padding: '20px 0 10px 0',
})

const Wrapper = styled('div')(() => ({
   display: 'flex',
   justifyContent: 'space-between',
}))

export const Header = () => {
   const dispatch = useDispatch<AppDispatch>()
   const [logoutModal, setLogoutModal] = useState(false)

   const logoutHandler = () => {
      dispatch(logout())
   }

   return (
      <>
         <StyledHeader>
            <Wrapper>
               <LogoIcon style={{ marginRight: 70 }} />
               <NavLinks />
            </Wrapper>
            <Button icon={<LogoutIcon />} onClick={() => setLogoutModal(true)}>
               log out
            </Button>
         </StyledHeader>
         <Modal
            open={logoutModal}
            title='Log out'
            acceptTitle='Log out'
            cancelTitle='Cancel'
            onAccept={logoutHandler}
            onCancel={() => setLogoutModal(false)}
            onClose={() => setLogoutModal(false)}
         >
            <ModalText text='Are you sure you want to log out of the site?' />
         </Modal>
      </>
   )
}
